import React from 'react';
import { createGlobalStyle } from 'styled-components';
import '@fontsource/press-start-2p';
import '@fontsource/audiowide';

// body { font-family: "Press Start 2P"; }
// body { font-family: "Audiowide"; }
// https://fontsource.org/fonts/audiowide

const GlobalStyle = createGlobalStyle`

*,
::after,
::before {
  box-sizing: border-box;
}


:root {

  /* 
Konvertáltás normálból hsluv-ba:
  https://www.hsluv.org/
primary: #ffb000   ->  50 100 77.5
secondary: #33ff00 -> 126.5 100 88.1

Példák HSLuv-al:
  https://www.kuon.ch/post/2020-03-08-hsluv/

  Secondary color keresésére jó:
https://mycolor.space/?hex=%23FFB000&sub=1

  Contrast checker (hsl-el működik!)
https://contrast-ratio.com/#white-on-hsla%28200%2C0%25%2C0%25%2C.7%29
*/

  /* CSS Variables
–––––––––––––––––––––––––––––––––––––––––––––––––– */

  /* COLORS */
  /* brand foundation */
  --logo-first-word-color: #33ff00;
  --logo-first-word-font:  "Press Start 2P";
  --logo-second-word-color: #ffb000;
  --logo-second-word-font:  "Audiowide";

  --primary-color-h: 50;
  --primary-color-s: 100%;
  --primary-color-l: 75%;

  --border-darken: 1.15;

  /* light */
  --primary-color-light: hsl(
    var(--primary-color-h) var(--primary-color-s) var(--primary-color-l)
  );

  --primary-color-light-text: hsl(
    var(--primary-color-h) var(--primary-color-s) 15%
  );

  --text1-light: hsl(var(--primary-color-h) var(--primary-color-s) 10%);
  --text2-light: hsl(var(--primary-color-h) 30% 30%);
  --surface1-light: hsl(var(--primary-color-h) 20% 85%);
  --surface2-light: hsl(var(--primary-color-h) 25% 90%);
  --surface3-light: hsl(var(--primary-color-h) 20% 92%);
  --surface4-light: hsl(var(--primary-color-h) 20% 99%);
  --primary-border-color-light: hsl(
    var(--primary-color-h) var(--primary-color-s)
      calc(var(--primary-color-l) / var(--border-darken))
  );

  /* dark */
  --primary-color-dark: hsl(
    var(--primary-color-h) calc(var(--primary-color-s) / 2)
      calc(var(--primary-color-l) / 1.5)
  );

  --primary-color-dark-text: hsl(
    var(--primary-color-h) calc(var(--primary-color-s) / 2)
      calc(var(--primary-color-l) / 1.5 - 40%)
  );

  --text1-dark: hsl(var(--primary-color-h) 15% 85%);
  --text2-dark: hsl(var(--primary-color-h) 5% 65%);
  --surface1-dark: hsl(var(--primary-color-h) 10% 10%);
  --surface2-dark: hsl(var(--primary-color-h) 10% 15%);
  --surface3-dark: hsl(var(--primary-color-h) 5% 20%);
  --surface4-dark: hsl(var(--primary-color-h) 5% 25%);
  --primary-border-color-dark: hsl(
    var(--primary-color-h) calc(var(--primary-color-s) / 2)
      calc((var(--primary-color-l) / 1.5) / var(--border-darken))
  );

  /* dim */
  --primary-color-dim: hsl(
    var(--primary-color-h) calc(var(--primary-color-s) / 1.25)
      calc(var(--primary-color-l) / 1.25)
  );

  --primary-color-dim-text: hsl(
    var(--primary-color-h) calc(var(--primary-color-s) / 1.25)
      calc(var(--primary-color-l) / 1.25 - 40%)
  );

  --text1-dim: hsl(var(--primary-color-h) 15% 75%);
  --text2-dim: hsl(var(--primary-color-h) 10% 61%);
  --surface1-dim: hsl(var(--primary-color-h) 10% 20%);
  --surface2-dim: hsl(var(--primary-color-h) 10% 25%);
  --surface3-dim: hsl(var(--primary-color-h) 5% 30%);
  --surface4-dim: hsl(var(--primary-color-h) 5% 35%);
  --primary-border-color-dim: hsl(
    var(--primary-color-h) calc(var(--primary-color-s) / 1.25)
      calc((var(--primary-color-l) / 1.25) / var(--border-darken))
  );

  /* secondary color */
  --secondary-color-h: 125;
  --secondary-color-s: 100%;
  --secondary-color-l: 85%;

  /* light */
  --secondary-color-light: hsl(
    var(--secondary-color-h) var(--secondary-color-s) var(--secondary-color-l)
  );

  --secondary-color-light-text: hsl(
    var(--secondary-color-h) var(--secondary-color-s) 20%
  );

  --secondary-border-color-light: hsl(
    var(--secondary-color-h) var(--secondary-color-s)
      calc(var(--secondary-color-l) / var(--border-darken))
  );

  --secondary-color-dark: hsl(
    var(--secondary-color-h) calc(var(--secondary-color-s) / 2)
      calc(var(--secondary-color-l) / 1.5)
  );

  --secondary-color-dark-text: hsl(
    var(--secondary-color-h) calc(var(--secondary-color-s) / 2)
      calc(var(--secondary-color-l) / 1.5 - 40%)
  );

  --secondary-border-color-dark: hsl(
    var(--secondary-color-h) calc(var(--secondary-color-s) / 2)
      calc((var(--secondary-color-l) / 1.5) / var(--border-darken))
  );

  --secondary-color-dim: hsl(
    var(--secondary-color-h) calc(var(--secondary-color-s) / 1.25)
      calc(var(--secondary-color-l) / 1.25)
  );

  --secondary-color-dim-text: hsl(
    var(--secondary-color-h) calc(var(--secondary-color-s) / 1.25)
      calc(var(--secondary-color-l) / 1.25 - 50%)
  );

  --secondary-border-color-dim: hsl(
    var(--secondary-color-h) calc(var(--secondary-color-s) / 1.25)
      calc((var(--secondary-color-l) / 1.25) / var(--border-darken))
  );

  /*  https://material.io/archive/guidelines/style/color.html#color-color-palette */

  --material-grey-50: #fafafa;
  --material-grey-100: #f5f5f5;
  --material-grey-200: #eeeeee;
  --material-grey-300: #e0e0e0;
  --material-grey-400: #bdbdbd;
  --material-grey-500: #9e9e9e;
  --material-grey-600: #757575;
  --material-grey-700: #616161;
  --material-grey-800: #424242;
  --material-grey-900: #212121;

  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* ---------------------------------------------------*/

  /* FONTS  */
  /* 62.5% of typical 16px browser default = 10px */
  --base-font-size: 62.5%;
  --heading-font: 'Noto Serif', serif;
  --body-font: 'Noto Sans', sans-serif;
  --small-text: 1.1rem;

  /* OTHERS */
  --background-color: var(--surface2-dark);
  --text-color: var(--text1-dark);
  --border-radius: 0.25rem;
  --letter-spacing: 1px;
  --transtion: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;

  /* BOX SHADOW*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}


/* STACKS */
--stack0: "--stacks: 0";
--stack1: "--stacks: 1";
--stack2: "--stacks: 2";
--stack3: "--stacks: 3";

/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

html {
  font-size: var(--base-font-size);
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--text-color);
  background: var(--background-color);
}

/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}
h1 {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}
h3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -0.1rem;
}
h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -0.08rem;
}
h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05rem;
}
h6 {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0;
}

/* Larger than phablet */
@media (min-width: 600px) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 3.6rem;
  }
  h4 {
    font-size: 3rem;
  }
  h5 {
    font-size: 2.4rem;
  }
  h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
}

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid var(--text-color);
}

/* Links
–––––––––––––––––––––––––––––––––––––––––––––––––– */
a {
  text-decoration: none;
  color: var(--secondary-color-dark);
}
a:hover {
  color: var(--secondary-color-light);
}

/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */
ul {
  list-style: circle inside;
}
ol {
  list-style: decimal inside;
}
ol,
ul {
  padding-left: 0;
  margin-top: 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
  font-size: 100%;
  margin: 1rem 0 1rem 3rem;
  color: var(--text1-light);
}
li {
  margin-bottom: 0.5rem;
}

/* Images
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.btn {
  display: inline-block;
  cursor: pointer;
  /* border: transparent; */
  /* border-radius: var(--border-radius); */
  /* letter-spacing: var(--letter-spacing); */
  /* padding: 0.375rem 0.75rem; */
  box-shadow: var(--shadow-1);
  transition: var(--transtion);
  /* Barebones CSS */
  height: 38px;
  padding: 0 30px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid var(--surface4-light);
  background-color: transparent;
  color: var(--surface4-light);
}

.btn:hover,
.btn:focus {
  border: 1px solid var(--surface1-light);
  background-color: transparent;
  color: var(--surface1-light);
  /* outline: 0; */
}

.btn-primary-empty {
  border: 1px solid var(--primary-color-light);
  background-color: transparent;
  color: var(--primary-color-light);
}

.btn-primary-empty:hover,
.btn-primary-empty:focus {
  border: 1px solid var(--primary-color-dim);
  background-color: transparent;
  color: var(--primary-color-dim);
  outline: 0;
}

.btn-secondary-empty {
  border: 1px solid var(--secondary-color-light);
  background-color: transparent;
  color: var(--secondary-color-light);
}

.btn-secondary-empty:hover,
.btn-secondary-empty:focus {
  border: 1px solid var(--secondary-color-dim);
  background-color: transparent;
  color: var(--secondary-color-dim);
  outline: 0;
}

.btn-primary {
  background: var(--primary-color-light);
  color: var(--primary-color-light-text);
  border: 1px solid var(--primary-border-color-light);
}
.btn-primary:hover {
  background: var(--primary-color-dark);
  color: var(--primary-color-dark-text);
  box-shadow: var(--shadow-3);
  border: 1px solid var(--primary-border-color-dark);
}
.btn-secondary {
  background: var(--secondary-color-light);
  color: var(--secondary-color-light-text);

  border: 1px solid var(--secondary-border-color-light);
}
.btn-secondary:hover {
  background: var(--secondary-border-color-dark);
  color: var(--secondary-color-dark-text);
  box-shadow: var(--shadow-3);
  border: 1px solid var(--secondary-border-color-dark);
}

.btn-primary-dim {
  background: var(--primary-color-dim);
  color: var(--primary-color-dim-text);
  border: 1px solid var(--primary-border-color-dim);
}

.btn-primary-dim:hover {
  background: var(--primary-color-light);
  color: var(--primary-color-light-text);
  border: 1px solid var(--primary-border-color-light);
}

.btn-secondary-dim {
  background: var(--secondary-color-dim);
  color: var(--secondary-color-dim-text);
  border: 1px solid var(--secondary-border-color-dim);
}

.btn-secondary-dim:hover {
  background: var(--secondary-color-light);
  color: var(--secondary-color-light-text);
  border: 1px solid var(--secondary-border-color-light);
}
.btn-block {
  width: 100%;
}

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* alerts */
.alert {
  padding: 0.6rem 1.2rem;
  margin-bottom: 1.6rem;
  border-color: transparent;
  border-radius: var(--border-radius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--surface2-light);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-2);
  padding: 3.2rem 4rem;
  margin: 4.8rem auto;
  color: var(--text1-light);
}
.form-label {
  display: block;
  font-size: var(--small-text);
  margin-bottom: 0.8rem;
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.6rem 1.2rem;
  border-radius: var(--border-radius);
  background: var(--surface3-light);
  border: 1px solid var(--surface1-light);
}

.form-row {
  margin-bottom: 1.6rem;
}

.form-textarea {
  height: 11rem;
}

${
  '' /* ::placeholder {
  font-family: inherit;
  color: var(--text2-light);
} */
}

.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
}
/* alert */


.loading {
  width: 9.5rem;
  height: 9.5rem;
  border: 5px solid var(--secondary-color-light);
  border-radius: 50%;
  border-top-color: var(--secondary-color-dark);
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-color-light);
  width: 11rem;
  height: 0.4rem;
  margin: 0 auto;
  margin-top: 1.6rem;
}

/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
Note: The best way to structure the use of media queries is to create the queries
near the relevant code. For example, if you wanted to change the styles for buttons
on small devices, paste the mobile query code up in the buttons section and style it
there.
*/

/* Larger than mobile (default point when grid becomes active) */
@media (min-width: 600px) {
};

/* Larger than phablet */
@media (min-width: 900px) {
};

/* Larger than tablet */
@media (min-width: 1200px) {
};






`;
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  );
};
